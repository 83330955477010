import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../layout"
import SEO from "../../components/SEO"
import Textblock from "../../components/Textblock"
import { DangerousHTML } from "../../components/contentful/HTML"

interface DataType {
  post: Contentful.Article
}

const PressReleaseTemplate = ({ data }: PageProps<DataType>) => {
  const { post } = data

  return (
    <Layout narrow>
      <SEO title={post.metadata.title} description={post.metadata.description} keywords={post.metadata.keywords} />
      <Textblock>
        <h1>{post.title}</h1>
        <time>{post.date}</time>
        <DangerousHTML>{post.body}</DangerousHTML>
      </Textblock>
    </Layout>
  )
}

export default PressReleaseTemplate

export const pageQuery = graphql`
  query ($slug: String!, $locale: String!) {
    post: contentfulPressRelease(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      slug
      date
      title
      body {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        title
        description
        keywords
      }
    }
  }
`
